<template>
  <div :class="{ message: 1, 'message--answer': data.data.side == 2 }">
    <div :class="{ message__avatar: 1, 'message__avatar--answer': data.data.side == 2 }">
      <img v-if="data.data.side == 2" src="img/edu/avatar3.png" />
      <img v-if="data.data.side == 1" src="img/edu/avatar2.png" />
    </div>
    <div :class="{ message__text: 1, 'message__text--answer': data.data.side == 2 }">
      <div v-if="data.data.side == 1" class="message__author"><span class="message__author-name">Dream-O-Robot</span> Руководитель отдела</div>
      <div v-if="data.data.side == 2" class="message__author message__avatar--answer">
        <span class="message__author-name">{{ $root.profile.name }}</span>
      </div>
      <p>
        {{ data.content }}
      </p>
      <button v-if="checkBtn" class="message__next" @click="$emit('next')">Далее</button>
    </div>
  </div>
</template>

<script>
import { getAccess, popupMenu, genModel } from "@/components/mixings";
export default {
  mixins: [getAccess, popupMenu, genModel],
  components: {},
  props: {
    data: Object,
    result: Object,
  },
  computed: {
    checkBtn() {
      return this.result?.status !== "done";
      return (this.result?.messages || []).find((m) => m.message_id == this.data.id)?.status !== "done";
    },
  },
  methods: {},
};
</script>

<style></style>
