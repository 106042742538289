<template>
  <div>
    <div class="text-overline my-0 pt-1">{{ model.title }}</div>
    <a-form-modelCols
      v-model="data"
      class="pb-1 mx-1 pt-1"
      :model="getFormModel(['action#3', 'message_id#9'], modelL)"
      :values="data"
      :errors="errors"
      :config="{ dense: true, readonly, hideDetails: true }"
      @validate="onInput($event)"
    />
  </div>
</template>

<script>
import { getForm, getAccess, libChecks, submitForm } from "@/components/mixings";
export default {
  name: "a-b",
  mixins: [getForm, libChecks, getAccess, submitForm],
  props: {
    value: Object,
    model: Object,
    config: {
      type: Object,
      default: () => {
        return { dense: true, hideDetails: "auto" };
      },
    },
    readonly: { type: Boolean, default: false },
    object: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    messages() {
      return (this.config?.opt?.messages || []).map((m) => {
        return { text: `#${m.id} ${m.content}`, value: m.id };
      });
    },
    modelL() {
      let type = [
        { value: "next", text: "Далее" },
        { value: "goto", text: "Идти на.." },
        { value: "stop", text: "Стоп" },
      ];
      let res = [
        { name: "action", title: "Действие", type: "select", options: type, validator: ["req"], readonly: false },
        {
          name: "message_id",
          title: "Сообщение",
          type: "select",
          options: this.messages,
          validator: this.data.action == "stop" || this.data.action == "next" || !this.data.action ? null : ["req"],
          hidden: this.data.action == "stop" || this.data.action == "next" || !this.data.action,
        },
      ];
      return res;
    },
  },
  created() {
    this.updateData();
  },
  watch: {
    value(v) {
      this.updateData();
    },
  },
  methods: {
    fillForm() {
      if (this.modelL) this.fillFormFromModel(this.modelL);
    },
    async fetchData() {
      this.loading = true;
      let data = this.value;
      if (!data) this.isChanged = true;
      for (const name in data) {
        this.$set(this.data, name, data[name]);
        this.$set(this.errors, name, null);
      }
      this.loading = false;

      this.afterFetchData(data);
    },
    async post(data, api, silent = false) {
      this.loading = true;
      try {
        this.loading = false;
        this.$emit("commit", data);
        this.afterSave(data);
      } catch (error) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка при сохранении",
        });
        this.loading = false;
        return;
      }
    },

    onInput(e) {
      if (e == "action") {
        this.fillFormValidators(this.modelL);
      }
      this.$emit("change");
      this.$emit("input", this.data);
      this.submit(true);
    },
  },
};
</script>
