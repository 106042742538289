<template>
  <div>
    <div :class="{ message: 1, 'message--answer': data.data.side == 2 }">
      <div :class="{ message__avatar: 1, 'message__avatar--answer': data.data.side == 2 }">
        <img v-if="data.data.side == 2" src="img/edu/avatar3.png" />
        <img v-if="data.data.side == 1" src="img/edu/avatar2.png" />
      </div>
      <div :class="{ message__text: 1, 'message__text--answer': data.data.side == 2 }">
        <div v-if="data.data.side == 1" class="message__author"><span class="message__author-name">Dream-O-Robot</span> Руководитель отдела</div>
        <div v-if="data.data.side == 2" class="message__author message__avatar--answer">
          <span class="message__author-name">{{ $root.profile.name }}</span>
        </div>
        <p>
          {{ data.content }}
        </p>
        <div class="message__image">
          <v-carousel>
            <v-carousel-item v-for="(item, i) in data.images" :key="i" :src="$root.config.imageUrl + item"></v-carousel-item>
          </v-carousel>
        </div>
        <div v-if="data.data.gallery && data.data.gallery.length">
          <div v-html="data.data.gallery[page - 1].text"></div>
          <img :src="$root.config.imageUrl + data.data.gallery[page - 1].images[0]" />
          <v-pagination v-model="page" :length="data.data.gallery.length" circle></v-pagination>
        </div>
        <button v-if="checkBtn" class="message__next" @click="$emit('next')">Далее</button>
      </div>
    </div>
  </div>
</template>

<script>
import { getAccess, popupMenu, genModel } from "@/components/mixings";
export default {
  mixins: [getAccess, popupMenu, genModel],
  components: {},
  props: {
    data: Object,
    result: Object,
  },
  data() {
    return {
      page: 1,
    };
  },
  computed: {
    checkBtn() {
      return this.result?.status !== "done";
    },
  },
  methods: {
    onAnswer(a) {
      if (!this.checkBtn) return;
    },
  },
};
</script>

<style></style>
