<template>
  <div style="text-align: left">
    <div class="text-overline mb-0 d-flex">
      Вопросы для тестирования
      <div class="text-left ml-1">
        <v-btn v-if="!readonly" elevation="2" small color="green" top left @click="onClickAdd" :disabled="![-2].includes(changed)">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn v-if="!readonly && list.length" elevation="0" small color="red" bottom @click="onClickDel(page - 1)">
          <v-icon>fas fa-trash-alt</v-icon>
        </v-btn>
      </div>
    </div>
    <div v-if="list.length">
      <v-pagination v-model="page" :length="list.length" circle :disabled="![-2].includes(changed)"></v-pagination>
      <a-input-education-message-question
        :key="page"
        :value="list[page - 1]"
        :config="{ dense: true, hideDetails: true }"
        :readonly="readonly"
        :object="object"
        @input="commit($event, page - 1)"
        @change="changed = page - 1"
      />
    </div>
  </div>
</template>

<script>
import { getAccess, libChecks } from "@/components/mixings";
export default {
  components: {
    //ButtonItem: () => import("./button"),
  },
  mixins: [libChecks, getAccess],
  props: {
    value: Array,
    object: {
      type: Object,
      default: () => {
        id: 0;
      },
    },
    config: {
      type: Object,
      default: () => {
        return { dense: true, hideDetails: "auto" };
      },
    },
    readonly: { type: Boolean, default: false },
    type: { type: Number, default: 0 },
  },
  data() {
    return {
      page: 1,
      changed: -2,
      isChanged: false,
      insert: false,
      newData: null,
    };
  },
  computed: {
    list: {
      set(v) {
        this.$emit("input", v);
      },
      get() {
        let res = [];
        if (this.value) res = this.value;
        if (!Array.isArray(res)) res = [];
        return res;
      },
    },
  },
  created() {
    //    this.reset();
  },
  watch: {
    value(v) {
      this.reset();
    },
    "object.id"(v) {},
  },
  methods: {
    onValid(e) {
      this.validate($event);
    },

    async reset() {
      this.changed = this.changed == this.list.length - 1 ? this.changed : -2;
    },
    onChange(i) {
      console.log("onchange", i);
      this.changed = i;
    },
    commit(e, i = -1) {
      console.log("comit", e, i);
      let d = [...this.list];
      if (i == -1) {
        d = [...d, e];
      } else d[i] = e;
      this.changed = -2;
      this.list = d;
      //this.$emit("input", d);
    },
    onClickDel(key) {
      console.log("del", key);
      let e = this.list;
      e.splice(key, 1);
      e.forEach((el, i) => {
        el.numb = i + 1;
      });
      this.list = e;
      this.page = this.list.length;
    },
    onClickAdd() {
      this.list.push({});
      this.page = this.list.length;
      this.changed = this.list.length - 1;
      return;
    },
  },
};
</script>
