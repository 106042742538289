<template>
  <div style="text-align: left">
    <div class="text-overline mb-0">Вопрос</div>
    <a-form-model
      v-model="data"
      :model="model"
      :errors="errors"
      :warnings="warnings"
      :config="{ dense: true, readonly, hideDetails: true, opt: config.opt || null }"
      @validate="onInput($event)"
      @fieldData="setFieldData($event)"
    />
    <div class="text-overline mb-0">Ответы</div>
    <v-row v-for="(el, i) in answers" :key="i" no-gutters class="mb-4">
      <v-col class="flex-grow-1 flex-shrink-0">
        <a-input-education-message-answer
          :value="answers[i]"
          :values="data"
          :config="config"
          :readonly="![-2, i].includes(changed) || readonly"
          @commit="
            commit($event, i);
            $emit('input', data);
          "
          @change="changed = i"
        />
        <hr v-if="i < answers.length - 1" class="mb-0 mt-3" />
      </v-col>
      <v-col v-if="!readonly" class="flex-grow-0 flex-shrink-1">
        <div class="pt-2">
          <v-btn elevation="0" fab small color="red" bottom @click="onClickDel(i)" :disabled="![-2, i].includes(changed)">
            <v-icon>mdi-minus</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <div class="text-left mt-1">
      <v-btn v-if="!readonly" elevation="2" fab x-small color="green" top left @click="onClickAdd" :disabled="![-2].includes(changed)">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { fillForm, submitForm, removeEl, getAccess, genModel, libChecks } from "@/components/mixings";
export default {
  components: {
    //ButtonItem: () => import("./button"),
  },
  mixins: [fillForm, submitForm, removeEl, getAccess, genModel, libChecks],
  props: {
    value: Object,
    object: {
      type: Object,
      default: () => {
        id: 0;
      },
    },
    config: {
      type: Object,
      default: () => {
        return { dense: true, hideDetails: "auto" };
      },
    },
    readonly: { type: Boolean, default: false },
    type: { type: Number, default: 0 },
  },
  data() {
    return {
      changed: -2,
    };
  },
  computed: {
    model() {
      let type = [
        { value: 1, text: "Кнопки" },
        { value: 2, text: "Выбор" },
        { value: 3, text: "Мультивыбор" },
      ];
      let res = [
        { name: "type", title: "Тип ответов", type: "select", options: type, validator: ["req"], default: 1 },
        { name: "text", title: "Текст вопроса", type: "text", rows: 2, validator: ["req"] },
        {
          name: "action_true",
          title: "Действие (правильно)",
          type: "education-message-action",
          validator: this.data?.type == 1 ? null : ["req"],
          hidden: this.data?.type == 1,
          return: "object",
        },
        {
          name: "action_false",
          title: "Действие (не правильно)",
          type: "education-message-action",
          validator: this.data?.type == 1 ? null : ["req"],
          hidden: this.data?.type == 1,
          return: "object",
        },
        { name: "answers", validator: ["req"] },
      ];
      return res;
    },
    answers: {
      set(v) {
        this.data.answers = [...v];
      },
      get() {
        let res = [];
        if (this.value) res = this.value?.answers || [];
        if (!Array.isArray(res)) res = [];
        return res;
      },
    },
  },
  created() {
    this.reset();
  },
  watch: {
    value(v) {
      this.reset();
    },
    "object.id"(v) {},
  },
  methods: {
    async fetchData(id) {
      for (const name in this.value) {
        this.$set(this.data, name, this.value[name]);
      }
      this.fillFormFromModel(this.model); // this.data = Object.assign({}, this.value || {});
    },
    onInput(e) {
      if (e == "type") {
        this.fillFormValidators(this.model);
      }
      this.validate(e);
      this.$emit("input", this.data);
    },
    async reset() {
      this.changed = this.changed == this.answers.length - 1 ? this.changed : -2;
      this.fetchData();
    },
    onChange(i) {
      this.changed = i;
    },
    commit(e, i = -1) {
      let d = [...this.answers];
      if (i == -1) {
        d = [...d, e];
      } else d[i] = e;
      this.changed = -2;
      this.answers = d;
      //this.$emit("input", d);
    },
    onClickDel(key) {
      let e = this.answers;
      e.splice(key, 1);
      e.forEach((el, i) => {
        el.numb = i + 1;
      });
      this.answers = e;
      this.changed = this.changed == this.answers.length - 1 ? this.changed : -2;
    },
    onClickAdd() {
      this.answers.push({});
      this.changed = this.answers.length - 1;
      return;
    },
  },
};
</script>
