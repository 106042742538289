<template>
  <div>
    <div class="test">
      <div class="test__top-text">Тестирование</div>
      <p>
        {{ data.content }}
      </p>
      <v-stepper v-model="test" alt-labels non-linear>
        <v-stepper-header>
          <template v-for="n in list.length">
            <v-stepper-step :key="`${n}-step`" :step="n" editable :color="test == n ? 'green' : ''"> Вопрос №{{ n }} </v-stepper-step>

            <v-divider v-if="n < list.length" :key="n"></v-divider>
          </template>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content v-for="n in list.length" :key="`${n}-content`" :step="n">
            <div class="test__content">
              <div class="test__info">
                <education-message-question
                  :istesting="true"
                  :key="n"
                  :result="test_results[n - 1]"
                  :data="{ data: { question: list[n - 1] } }"
                  @answerTesting="onAnswer(n - 1, $event)"
                />
              </div>
            </div>
          </v-stepper-content>
          <div v-if="completed == list.length" :key="`result-content`">
            <div class="test__content">
              <div class="test__info">
                <h3 class="test__h3">Ваш результат</h3>
                <div v-if="!test_result">
                  <p>Вы допустили ошибки в тестировании. Пожалуйста, изучите этот блок еще раз</p>
                  <button class="test__error-text" @click="$emit('restart', { test_result, test_results })">Начать заново</button>
                </div>
                <div v-if="test_result">
                  <p>Вы ответили правильно на все вопросы</p>
                  <button v-if="status != 3" class="message__next message__next--yellow" @click="$emit('complete', { test_result, test_results })">Завершить блок</button>
                  <button v-else class="message__next message__next--yellow" @click="notifi()">Блок завершен</button>
                </div>
              </div>
            </div>
          </div>
        </v-stepper-items>
      </v-stepper>
    </div>
  </div>
</template>

<script>
import { getAccess, popupMenu, genModel } from "@/components/mixings";
export default {
  mixins: [getAccess, popupMenu, genModel],
  components: {},
  props: {
    data: Object,
    result: Object,
    status: Number,
  },
  data() {
    return {
      test: 1,
      radioResult: null,
      checkResult: [],
      test_result: true,
      test_results: {},
    };
  },
  computed: {
    completed() {
      return Object.keys(this.test_results).length;
    },
    list() {
      return this.data.data.testing;
    },
    checkBtn() {
      return (this.result?.messages || []).find((m) => m.message_id == this.data.id)?.status !== "done";
    },
  },
  created() {
    if (this.result) {
      this.test_result = this.result?.result?.test_result;
      this.test_results = this.result?.result?.test_results || {};
      this.test = Object.keys(this.test_results).length + 1;
    }
  },
  watch: {
    result(v) {
      console.log("watch result", v);
    },
  },
  methods: {
    notifi() {},
    onAnswer(n, a) {
      if (!this.checkBtn) return;
      let q = this.list[n];
      this.test_result = this.test_result && a.istrue;
      this.$set(this.test_results, n, { text: q.text, answer: a.answer });
      //this.test_results[n] = { text: q.text, answer: a.answer };
      // this.test_results[n] = { text: q.text, answer: a.text };
      this.test++;
    },
    onAnswerRadio(n, a) {
      if (!this.checkBtn) return;
      let q = this.list[n];
      let res = false;
      if (q.answers[a].istrue) res = true;
      this.test_result = this.test_result && res;
      this.$set(this.test_results, n, { text: q.text, answer: a.answers[a] });
      //      this.test_results[n] = { text: q.text, answer: q.answers[a] };
      this.test++;
    },
    onAnswerCheck(n, a) {
      if (!this.checkBtn) return;
      let res = true;
      let q = this.list[n];
      q.answers.forEach((el, i) => {
        res = res && el.istrue == a.includes(i);
      });
      this.test_result = this.test_result && res;
      this.$set(this.test_results, n, { text: q.text, answer: q.answers.filter((el, i) => a.includes(i)) });
      //this.test_results[n] = { text: q.text, answer: q.answers.filter((el, i) => a.includes(i)) };
      this.test++;
    },
  },
};
</script>

<style></style>
