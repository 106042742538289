<template>
  <div style="text-align: left">
    <div class="text-overline mb-0">Галерея</div>
    <v-row v-for="(el, i) in list" :key="i" no-gutters class="mb-4">
      <v-col class="flex-grow-1 flex-shrink-0">
        <a-input-education-message-gallery-item
          :value="list[i]"
          :config="config"
          :readonly="![-2, i].includes(changed) || readonly"
          @commit="commit($event, i)"
          @change="changed = i"
        />
        <hr class="my-1" />
      </v-col>
      <v-col v-if="!readonly" class="flex-grow-0 flex-shrink-1">
        <div class="pt-2">
          <v-btn elevation="0" fab small color="red" bottom @click="onClickDel(i)" :disabled="![-2, i].includes(changed)">
            <v-icon>mdi-minus</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <div class="text-left mt-1">
      <v-btn v-if="!readonly" elevation="2" fab x-small color="green" top left @click="onClickAdd" :disabled="![-2].includes(changed)">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { getAccess, genModel, libChecks } from "@/components/mixings";
export default {
  components: {
    //ButtonItem: () => import("./button"),
  },
  mixins: [getAccess, genModel, libChecks],
  props: {
    value: Array,
    object: {
      type: Object,
      default: () => {
        id: 0;
      },
    },
    config: {
      type: Object,
      default: () => {
        return { dense: true, hideDetails: "auto" };
      },
    },
    readonly: { type: Boolean, default: false },
    type: { type: Number, default: 0 },
  },
  data() {
    return {
      changed: -2,
    };
  },
  computed: {
    list: {
      set(v) {
        this.$emit("input", v);
      },
      get() {
        let res = [];
        if (this.value) res = this.value;
        if (!Array.isArray(res)) res = [];
        return res;
      },
    },
  },
  created() {
    // this.reset();
  },
  watch: {
    value(v) {
      this.reset();
    },
    "object.id"(v) {},
  },
  methods: {
    onValid(e) {
      this.validate($event);
    },

    async reset() {
      this.changed = this.changed == this.list.length - 1 ? this.changed : -2;
    },
    onChange(i) {
      console.log("onchange", i);
      this.changed = i;
    },
    commit(e, i = -1) {
      let d = [...this.list];
      if (i == -1) {
        d = [...d, e];
      } else d[i] = e;
      this.changed = -2;
      this.list = d;
      //this.$emit("input", d);
    },
    onClickDel(key) {
      let e = this.list;
      e.splice(key, 1);
      e.forEach((el, i) => {
        el.numb = i + 1;
      });
      this.list = e;
    },
    onClickAdd() {
      this.list.push({});
      this.changed = this.list.length - 1;
      return;
    },
  },
};
</script>
