<template>
  <div>
    <div v-if="data.data && data.data.side" :class="{ message: 1, 'message--answer': data.data.side == 2 }">
      <div :class="{ message__avatar: 1, 'message__avatar--answer': data.data.side == 2 }">
        <img v-if="data.data.side == 2" src="img/edu/avatar3.png" />
        <img v-if="data.data.side == 1" src="img/edu/avatar2.png" />
      </div>
      <div :class="{ message__text: 1, 'message__text--answer': data.data.side == 2 }">
        <div v-if="data.data.side == 1" class="message__author"><span class="message__author-name">Dream-O-Robot</span> Руководитель отдела</div>
        <div v-if="data.data.side == 2" class="message__author message__avatar--answer">
          <span class="message__author-name">{{ $root.profile.name }}</span>
        </div>
        <p>
          {{ data.content }}
        </p>
      </div>
    </div>
    <div v-if="question.type == 1" class="message">
      <div :class="{ 'message__text message__text--silver': !istesting }">
        <h3 class="message__buttons-h3">{{ question.text }}</h3>
        <div class="message__buttons">
          <button
            v-for="(a, i) in question.answers"
            :key="i"
            :class="{
              message__button: true,
              'message__button--color1': checkBtn || (answer && answer.text == a.text),
              'message__button--color2': !(checkBtn || (answer && answer.text == a.text)),
            }"
            @click="onAnswer(a)"
          >
            {{ a.text }}
          </button>
        </div>
      </div>
    </div>
    <div v-if="question.type == 2" class="message">
      <div :class="{ 'message__text message__text--silver': !istesting }">
        <h3 class="test__h3">{{ question.text }}</h3>
        <div class="message__buttons">
          <v-radio-group v-model="radioResult" :disabled="!!answer">
            <v-radio v-for="(a, i) in question.answers" :key="i" :label="a.text" :value="i"></v-radio>
          </v-radio-group>
          <v-btn v-if="!answer" color="green" class="mt-4" :disabled="!radioResult && radioResult !== 0" @click="onAnswerRadio(radioResult)"> Ответить </v-btn>
        </div>
      </div>
    </div>
    <div v-if="question.type == 3" class="message">
      <div :class="{ 'message__text message__text--silver': !istesting }">
        <h3 class="test__h3">{{ question.text }}</h3>
        <div class="message__buttons">
          <v-checkbox v-model="checkResult" v-for="(a, i) in question.answers" :key="i" :label="a.text" :value="i" dense hide-details :disabled="!!answer"></v-checkbox>

          <v-btn v-if="!answer" color="green" class="mt-4" :disabled="!checkResult.length" @click="onAnswerCheck(checkResult)"> Ответить </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAccess, popupMenu, genModel } from "@/components/mixings";
export default {
  mixins: [getAccess, popupMenu, genModel],
  components: {},
  props: {
    istesting: { type: Boolean, default: false },
    data: Object,
    result: Object,
  },
  data() {
    return {
      radioResult: null,
      checkResult: [],
    };
  },
  computed: {
    question() {
      return this.data.data.question;
    },
    answer() {
      return this.result?.answer;
      return (this.result?.messages || []).find((m) => m.message_id == this.data.id)?.answer;
    },
    checkBtn() {
      return !(this.result?.status == "done" || this.result?.answer);
      return (this.result?.messages || []).find((m) => m.message_id == this.data.id)?.status !== "done";
    },
  },
  created() {
    if (this.answer) {
      if (this.question.type == 2) {
        this.radioResult = this.question.answers.findIndex((a) => a.text === this.answer.text);
      }
      if (this.question.type == 3) {
        this.question.answers.forEach((a, i) => {
          if (this.answer.map((aa) => aa.text).includes(a.text)) this.checkResult.push(i);
        });
      }
    }
  },
  methods: {
    onAnswer(a) {
      if (!this.checkBtn) return;
      if (this.istesting) {
        this.$emit("answerTesting", { answer: a, istrue: a.istrue });
        return;
      }
      if (a.action == "next") {
        this.$emit("goto", { answer: a, next_id: this.data.next_id });
      }
      if (a.action == "stop") {
      }
      if (a.action == "goto") {
        this.$emit("goto", { answer: a, next_id: a.message_id });
      }
    },
    onAnswerRadio(a) {
      if (!this.checkBtn) return;
      const q = this.question;
      let res = q.answers[a].istrue;
      if (this.istesting) {
        this.$emit("answerTesting", { answer: q.answers[a], istrue: res });
        return;
      }
      let action = res ? q.action_true : q.action_false;
      console.log(q, action.action);
      if (action.action == "next") {
        this.$emit("goto", { answer: q.answers[a], next_id: this.data.next_id });
      }
      if (action.action == "stop") {
      }
      if (action.action == "goto") {
        this.$emit("goto", { answer: a, next_id: action.message_id });
      }
      return;
      this.test_result = this.test_result && res;
      this.test_results[n] = { text: q.text, answer: q.answers[a] };
    },
    onAnswerCheck(a) {
      if (!this.checkBtn) return;
      let res = true;
      let q = this.question;
      q.answers.forEach((el, i) => {
        res = res && el.istrue == a.includes(i);
      });
      if (this.istesting) {
        this.$emit("answerTesting", { answer: q.answers.filter((el, i) => a.includes(i)), istrue: res });
        return;
      }
      let action = res ? q.action_true : q.action_false;
      if (action.action == "next") {
        this.$emit("goto", { answer: q.answers[a], next_id: this.data.next_id });
      }
      if (action.action == "stop") {
      }
      if (action.action == "goto") {
        this.$emit("goto", { answer: a, next_id: action.message_id });
      }
      return;
      this.test_result = this.test_result && res;
      this.test_results[n] = { text: q.text, answer: q.answers.filter((el, i) => a.includes(i)) };
      this.test++;
    },
  },
};
</script>

<style></style>
