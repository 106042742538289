<template>
  <div>
    <a-form-modelCols
      v-model="data"
      class="pb-1 mx-1 mt-1"
      :model="getFormModel(['text#12', 'istrue#4', 'action#4', 'message_id#12'])"
      :values="data"
      :errors="errors"
      :config="{ dense: true, readonly, hideDetails: true }"
      @validate="onInput($event)"
    />
  </div>
</template>

<script>
import { getForm, getAccess, libChecks, submitForm } from "@/components/mixings";
export default {
  name: "a-b",
  mixins: [getForm, libChecks, getAccess, submitForm],
  props: {
    value: Object,
    values: Object,
    config: {
      type: Object,
      default: () => {
        return { dense: true, hideDetails: "auto" };
      },
    },
    readonly: { type: Boolean, default: false },
    object: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      model1: [
        { name: "text", title: "Текст ответа", type: "text", rows: 1, validator: ["req"] },
        { name: "istrue", title: "Правильно", type: "switcher", default: 0 },
        {
          name: "action",
          title: "Действие",
          type: "select",
          options: [
            { value: "next", text: "Далее" },
            { value: "goto", text: "Идти на.." },
            { value: "stop", text: "Стоп" },
          ],
          validator: ["req"],
        },
        { name: "message_id", title: "Сообщение", type: "select", options: [], hidden: true },
      ],
    };
  },
  computed: {
    messages() {
      return (this.config?.opt?.messages || []).map((m) => {
        return { text: `#${m.id} ${m.content}`, value: m.id };
      });
    },
    model() {
      let type = [
        { value: "next", text: "Далее" },
        { value: "goto", text: "Идти на.." },
        { value: "stop", text: "Стоп" },
      ];
      let res = [
        { name: "text", title: "Текст ответа", type: "text", rows: 1, validator: ["req"] },
        { name: "istrue", title: "Правильно", type: "switcher", default: 0 },
        {
          name: "action",
          title: "Действие",
          type: "select",
          options: type,
          validator: this.values.type !== 1 ? null : ["req"],

          hidden: this.values.type !== 1,
          readonly: false,
        },
        {
          name: "message_id",
          title: "Сообщение",
          type: "select",
          options: this.messages,
          hidden: this.values.type !== 1 || this.data.action == "stop" || this.data.action == "next" || !this.data.action,
        },
      ];
      return res;
    },
  },
  created() {
    this.updateData();
  },
  watch: {
    value(v) {
      this.updateData();
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      let data = this.value;
      if (!data) this.isChanged = true;
      for (const name in data) {
        this.$set(this.data, name, data[name]);
        this.$set(this.errors, name, null);
      }
      this.loading = false;

      this.afterFetchData(data);
    },
    async post(data, api, silent = false) {
      this.loading = true;
      try {
        this.loading = false;
        this.$emit("commit", data);
        this.afterSave(data);
      } catch (error) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка при сохранении",
        });
        this.loading = false;
        return;
      }
    },
    onInput(e) {
      if (e == "action") {
        let m = this.model.find((m) => m.name == "message_id");
        // m.hidden = this.data.action == "stop" || this.data.action == "next";
        m.validator = !m.hidden ? ["req"] : null;
        // if (!m.hidden) m.options = this.messages;
        // console.log("mmmm", this.model, this.messages);
        this.fillFormValidators(this.model);
      }
      this.$emit("change");
      this.submit(true);
    },
    commit() {
      this.$emit("input", this.list);
    },
    onClickDel(key) {
      let e = this.list;
      e.splice(key, 1);
      e.forEach((el, i) => {
        el.numb = i + 1;
      });
      this.list = e;
      this.commit();
      this.reset();
      if (this.curError?.id == key) {
        this.curError = {};
      }
    },
  },
};
</script>
