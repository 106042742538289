/** @format */

import Vue from 'vue';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';

function registerComponents(requireComponent) {
  requireComponent.keys().forEach((fileName) => {
    const componentConfig = requireComponent(fileName);

    let componentName; //= 'a-input-education' + upperFirst(camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')));
    componentName = camelCase(
      fileName
        .replace(/^\.\//, '')
        .replace(/\//g, '-')
        .replace(/\.\w+$/, '')
    );
    //console.log('registred', componentName, fileName);
    Vue.component(`${componentName}`, componentConfig.default || componentConfig);
  });
}

// registerComponents(require.context(
//   '@/share/components', true, /\.vue$/,
// ))

registerComponents(require.context('@/views/study/components', true, /\.vue$/));
